import { post } from '@/utils/request'


export function add(data) {
    return post('/room/cleaning/addCleaning', data)
}


export function getList(data) {
    return post('/room/cleaning/cleaningList', data)
}

export function getInfo(data) {
    return post('/room/cleaning/cleaningInfo', data)
}

export function edit(data) {
    return post('/room/cleaning/editCleaning', data)
}

export function del(data) {
    return post('/room/cleaning/delCleaning', data)
}


export function getUserList(data) {
    return post('admin/storage/getUserList', data)
}

export function getRoomList(data) {
    return post('/room/room/roomList', data)
}